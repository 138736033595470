.login{
    margin: 100px auto;
    width: 350px;
}
#loginDiv {
    display: flex;        
    justify-content: center;
    align-items: center;  
    margin-top: 20px;    
}

.separator {
    font-weight: normal;      
    color: #656565;              
	text-align: center;
	margin: 20px 0;
	position: relative;
}

.separator::before,
.separator::after {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 1px;
	width: 40%;
	background-color: #ccc;
}

.separator::before {
	left: 0;
}

.separator::after {
	right: 0;
}