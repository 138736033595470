html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    position: fixed;
    overscroll-behavior-y: none;
}

#root {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overscroll-behavior-y: none;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 12px 0px !important;
    margin-bottom: 90px;
}