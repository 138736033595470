.arrowRight {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.arrowLeft {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.main-firstline {
	width: 100%;

	margin: 0 0 12px 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: first baseline;
}

.main-firstline-title {
}

.main-firstline-sessionchoice {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: first baseline;
}

.main-firstline-sessionchoice-button {
}

.pagination-numbers {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.pagination-number {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 4px;
}

/* FiltersBox */
.filtersBoxButton,
.main-firstline-sessionchoice-button {
	position: relative;
	min-width: 80px;
	height: 2em;

	margin: 0 0 0 6px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border: 1px solid black;
	border-radius: 4px;
	cursor: pointer;

	background-color: rgba(33, 37, 41, 1);
	color: rgba(255, 255, 255, 0.55);
}

.filtersBox {
	position: absolute;
	z-index: 2000;
	min-height: 100px;
	min-width: 100px;

	top: calc(100% + 6px);
	right: 0%;

	background-color: rgb(148, 148, 148);
	border: 1px solid rgba(33, 37, 41, 1);
	border-radius: 4px;

	filter: drop-shadow(0 0 0.35rem rgba(129, 129, 129, 0.5));

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.filterBoxPart {
	margin: 12px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.filterBox-label {
	min-width: 160px;

	color: rgba(33, 37, 41, 1);
}

.filterBox-buttons {
	width: calc(100% - 24px);

	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.filterBox-button {
	margin: 0 0 0 6px;
}
