.touchscreen-button {
    cursor: pointer;
  }
  
.touchscreen-popup {
    position: absolute;
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    border: none;
    background: none;
    cursor: pointer;
    color: red;
    font-size: 1.2em;
}

.popup-option {
    cursor: pointer;
    margin-top: 5px;
}

.popup-status-label {
    margin-right: 10px;
}